import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useTradeOutlets } from "entities/trade-outlets";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { CreateTradeOutletDialog } from "features/create-trade-outlet";
import { DeleteTradeOutletDialog } from "features/delete-trade-outlet";
import { TradeOutletDetails } from "features/trade-outlet-details";
import { UpdateTradeOutletDialog } from "features/update-trade-outlet";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetTradeOutletsItem } from "shared/api";
import {
  analytics,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Button,
  Card,
  FilterType,
  IconAdd,
  LoadingOverlay,
  SearchInput,
  Show,
  SortingOptions,
  Stack,
  Typography,
} from "shared/ui";

export const TradeOutletList = () => {
  const { t } = useTranslation();

  const columns: ColumnDef<GetTradeOutletsItem, any>[] = [
    {
      accessorKey: "name",
      header: t("trade_outlets.table.column.name"),
    },
    {
      accessorKey: "location",
      header: t("trade_outlets.table.column.location"),
    },
    {
      accessorKey: "archived",
    },
  ];

  const sortingOptions: SortingOptions<GetTradeOutletsItem> = {
    name: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "name",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-name",
      },
    ],
    location: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "location",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-location",
      },
    ],
  };

  const createDialog = useBoolean();
  const editDialog = useBoolean();
  const deleteDialog = useBoolean();

  const tradeOutletsOptions = useTradeOutletsOptions();

  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetTradeOutletsItem>(sortingOptions);

  const filtersConfig = [
    {
      field: "id",
      type: FilterType.Multiple,
      entityLabel: t("trade_outlets.table.filter.id.label"),
      options:
        tradeOutletsOptions.data?.map((item) => ({
          id: item.id,
          name: `${item.name}${item.location ? ` (${item.location})` : ""}`,
        })) || [],
    },
  ];

  const {
    filterState,
    handleFilterChange,
    handleFiltersReset,
    isFiltersEmpty,
  } = useTableFilters<GetTradeOutletsItem>(filtersConfig);

  const handleRowSelect = (row: Row<GetTradeOutletsItem>) => {
    setSelectedRowId(row.id);
  };

  const handleResetRowSelection = () => {
    if (tradeOutlets.data?.items?.[0]) {
      setSelectedRowId(tradeOutlets.data?.items[0].id);
    }
  };

  const tradeOutlets = useTradeOutlets({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    id_in: filterState.id,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceTradeOutletsOrderBy,
    search_text: searchValue,
  });

  const [selectedRowId, setSelectedRowId] = useState<string | null>();

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      tradeOutlets.data?.items?.length &&
      tradeOutlets.data?.items[0]
    ) {
      setSelectedRowId(tradeOutlets.data?.items[0].id);
    }
  }, [selectedRowId, tradeOutlets.data?.items]);

  const selectedRowData = useMemo(() => {
    if (!tradeOutlets.data?.items || !selectedRowId) return undefined;

    return tradeOutlets.data.items.find((item) => item.id === selectedRowId);
  }, [selectedRowId, tradeOutlets.data?.items]);

  const getRowId = (row: GetTradeOutletsItem) => row.id!;

  const handleAddTradeOutletClick = () => {
    analytics.logAction("ClickCreateStore");

    createDialog.on();
  };

  const handleFilterDropdownOpen = (field: string) => {
    analytics.logAction("OpenStoreFilter", { field });
  };

  const handleSearchButtonClick = (value: string) => {
    analytics.logAction("SearchStore", { search_string: value });
  };

  const handleDeleteTradeOutletClick = () => {
    analytics.logAction("ClickDeleteStore");

    deleteDialog.on();
  };

  const handleEditTradeOutletClick = () => {
    analytics.logAction("ClickUpdateStore");

    editDialog.on();
  };

  const handleCreateTradeOutletSuccess = (tradeOutletId: string) => {
    setSelectedRowId(tradeOutletId);
  };

  const isSearchVisible =
    (tradeOutlets.data?.items || []).length > 0 ||
    Boolean(searchValue) ||
    !isFiltersEmpty;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">{t("trade_outlets.title")}</Typography>

        <Stack direction="row" spacing={1.5}>
          <Show when={isSearchVisible}>
            <SearchInput
              onClear={handleSearchClear}
              onSubmit={handleSearchSubmit}
              defaultValue={searchValue}
              onButtonClick={handleSearchButtonClick}
              placeholder={t("trade_outlets.search_input.placeholder")}
            />
          </Show>

          <Button onClick={handleAddTradeOutletClick} endIcon={<IconAdd />}>
            {t("trade_outlets.add_trade_outlet_button_text")}
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 560, flex: 1 }}>
          <BaseTable
            columns={columns}
            data={tradeOutlets.data?.items || []}
            sortingState={sortingState}
            onSortingChange={handleSortingChange}
            sortingOptions={sortingOptions}
            filters={filtersConfig}
            filterState={filterState}
            onFilterChange={handleFilterChange}
            onFiltersReset={handleFiltersReset}
            isDataLoading={tradeOutlets.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
            deletedColumnId="archived"
            onFilterDropdownOpen={handleFilterDropdownOpen}
            isFiltersEnabled={!isFiltersEmpty || Boolean(searchValue)}
            noDataMessage={t("trade_outlets.table.no_data_message")}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: tradeOutlets.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <Card sx={{ width: 480, height: 560 }}>
          <TradeOutletDetails
            onDeleteClick={handleDeleteTradeOutletClick}
            onEditClick={handleEditTradeOutletClick}
            tradeOutlet={selectedRowData}
          />
        </Card>
      </Stack>

      <LoadingOverlay open={tradeOutlets.isLoading} />

      <CreateTradeOutletDialog
        isOpen={createDialog.value}
        onClose={createDialog.off}
        onSuccess={handleCreateTradeOutletSuccess}
      />
      <UpdateTradeOutletDialog
        tradeOutlet={selectedRowData}
        isOpen={editDialog.value}
        onClose={editDialog.off}
      />
      <DeleteTradeOutletDialog
        isOpen={deleteDialog.value}
        onClose={deleteDialog.off}
        tradeOutlet={selectedRowData}
        onSuccess={handleResetRowSelection}
      />
    </>
  );
};
