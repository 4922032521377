import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type DebtCompensationItem = {
  archived: bnplMerchants.DebtCompensationMany["archived"];
  compensationAmount: bnplMerchants.DebtCompensationMany["compensation_amount"];
  created: Date | undefined;
  debtId: bnplMerchants.DebtCompensationMany["debt_id"];
  id: bnplMerchants.DebtCompensationMany["id"];
  settlementId: bnplMerchants.DebtCompensationMany["settlement_id"];
  settlementNumber: bnplMerchants.DebtCompensationMany["settlement_number"];
  updated: Date | undefined;
};

export type GetDebtCompensationsParams = {
  limit?: number;
  offset?: number;
};

type GetDebtCompensationsResponseData = {
  items: DebtCompensationItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export const getDebtCompensations = async (
  debtId?: string,
  params?: GetDebtCompensationsParams,
): Promise<GetDebtCompensationsResponseData> => {
  if (!debtId) {
    throw new Error("debtId is missing");
  }

  const response =
    await bnplMerchants.getApiMerchantsServiceDebtsIdCompensations(
      debtId,
      params,
    );

  return {
    items: response.data.data?.items?.map((i) => ({
      archived: i.archived,
      compensationAmount: i.compensation_amount,
      created: i.created ? new Date(i.created) : undefined,
      debtId: i.debt_id,
      id: i.id,
      settlementId: i.settlement_id,
      settlementNumber: i.settlement_number,
      updated: i.updated ? new Date(i.updated) : undefined,
    })),
    pagination: response.data.data?.pagination,
  };
};
