import { ColumnDef, Row } from "@tanstack/react-table";
import { useDebts } from "entities/debts";
import { DebtDetails } from "features/debt-details";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetDebtItem } from "shared/api/debts/get-debts";
import { format, formatDate, useTablePagination } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Box,
  Card,
  IconToFill,
  InfoBlock,
  Label,
  LoadingOverlay,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { getStatusMappingData } from "../lib";

export const DebtList = () => {
  const { t } = useTranslation();

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const pagination = useTablePagination();

  const debts = useDebts({
    limit: pagination.currentLimit,
    offset: pagination.offset,
  });

  const isDebtsEmpty = !debts.data || debts.data?.items?.length === 0;

  const columns: ColumnDef<GetDebtItem, any>[] = [
    {
      accessorKey: "orderNumber",
      header: t("debts.table.column.order_number"),
    },
    {
      accessorKey: "status",
      header: t("debts.table.column.status"),
      cell: (cell) => {
        const { title, color } = getStatusMappingData(cell.getValue());
        return <Label color={color}>{title}</Label>;
      },
    },
    {
      accessorKey: "created",
      header: t("debts.table.column.created"),
      cell: (cell) => {
        const value = cell.getValue();
        return value instanceof Date ? formatDate(value, "DD/MM/YYYY") : "";
      },
    },
    {
      accessorKey: "debtAmount",
      header: t("debts.table.column.debt_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "compensatedAmount",
      header: t("debts.table.column.compensated_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "currentDebtAmount",
      header: t("debts.table.column.current_debt_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
  ];

  const handleRowSelect = (row: Row<GetDebtItem>) => {
    setSelectedRowId(row.id);
  };

  const getRowId = (row: GetDebtItem) => row.id!;

  const selectedRowData = useMemo(() => {
    if (!debts.data?.items || !selectedRowId) return undefined;

    return debts.data.items.find((item) => item.id === selectedRowId);
  }, [selectedRowId, debts.data?.items]);

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      debts.data?.items?.length &&
      debts.data?.items[0]
    ) {
      setSelectedRowId(debts.data?.items[0].id);
    }
  }, [selectedRowId, debts.data?.items]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 560, flex: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={2.5}
          >
            <Typography variant="headlineH1">Debits</Typography>

            <Box sx={{ minWidth: "248px" }}>
              <InfoBlock
                title={t("debts.total_debit.label")}
                Icon={IconToFill}
                description={format(debts.data?.summary?.totalCurrentDebt || 0)}
                variant="row"
              />
            </Box>
          </Stack>

          <BaseTable
            columns={columns}
            data={debts.data?.items || []}
            isDataLoading={debts.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
            noDataMessage={t("debts.table.no_data_message")}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: debts.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <LoadingOverlay open={debts.isLoading} />

        <Card sx={{ width: 480, height: 560 }}>
          <Show when={!isDebtsEmpty}>
            <DebtDetails
              debtId={selectedRowId}
              orderNumber={selectedRowData?.orderNumber}
            />
          </Show>
        </Card>
      </Stack>
    </>
  );
};
