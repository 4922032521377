import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { getDebts } from "shared/api";

import { QUERY_KEY_DEBTS } from "../../lib";

export const useDebts = (
  options?: bnplMerchants.GetApiMerchantsServiceDebtsParams,
) =>
  useQuery({
    queryFn: () => getDebts(options),
    queryKey: [QUERY_KEY_DEBTS, options],
  });
