import { ColumnDef } from "@tanstack/react-table";
import { useDebtCompensations } from "entities/debts/model/use-debt-compensations";
import { getOrdersPath } from "entities/orders";
import {
  formatSettlementNumber,
  getSettlementsPath,
} from "entities/settlements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DebtCompensationItem } from "shared/api";
import { format, formatDate, useTablePaginationState } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  BaseTooltip,
  Box,
  IconButton,
  IconOpenIn,
  Show,
  Stack,
  TableCellLinkButton,
  Typography,
} from "shared/ui";

type DebtDetailsProps = {
  debtId?: string;
  orderNumber?: string;
};

export const DebtDetails = ({ debtId, orderNumber }: DebtDetailsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const pagination = useTablePaginationState();

  const compensations = useDebtCompensations(debtId, {
    limit: pagination.currentLimit,
    offset: pagination.offset,
  });

  const isTableEmpty =
    !compensations.data?.items || compensations.data.items.length === 0;

  const getRowId = (row: any) => row.id!;

  const handleGoToOrder = () => {
    navigate(getOrdersPath({ search: orderNumber }));
  };

  const columns: ColumnDef<DebtCompensationItem, any>[] = [
    {
      accessorKey: "settlementNumber",
      header: t("debts.details.compensations.column.settlement_number"),
      cell: (cell) => {
        const value = cell.getValue();
        const formattedValue = formatSettlementNumber(value);

        return formattedValue ? (
          <TableCellLinkButton
            text={formattedValue}
            path={getSettlementsPath({ search: cell.getValue() })}
          />
        ) : undefined;
      },
    },
    {
      accessorKey: "created",
      header: t("debts.details.compensations.column.created"),
      cell: (cell) => {
        const value = cell.getValue();
        return value instanceof Date ? formatDate(value, "DD/MM/YYYY") : "";
      },
    },
    {
      accessorKey: "compensationAmount",
      header: t("debts.details.compensations.column.compensation_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
  ];

  return (
    <Stack sx={{ height: "100%" }} spacing={2.5}>
      <Stack
        px={3}
        py={2.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="neutral.95"
      >
        <Typography color="neutral.20" variant="headlineH4">
          {t("debts.details.title", { id: orderNumber })}
        </Typography>

        <BaseTooltip
          title={t("debts.details.order_link.hint")}
          placement="bottom"
        >
          <div>
            <IconButton color="gray" onClick={handleGoToOrder}>
              <IconOpenIn />
            </IconButton>
          </div>
        </BaseTooltip>
      </Stack>
      <Box sx={{ flex: 1 }}>
        <Show when={!isTableEmpty}>
          <BaseTableWrapper sx={{ height: 455 }}>
            <BaseTable
              data={compensations.data?.items || []}
              isDataLoading={compensations.isLoading}
              columns={columns}
              getRowId={getRowId}
            />
            <BaseTablePagination
              state={{
                ...pagination,
                totalCount: compensations.data?.pagination?.total,
              }}
            />
          </BaseTableWrapper>
        </Show>
      </Box>
    </Stack>
  );
};
