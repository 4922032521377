import { useSearchParams } from "react-router-dom";

import { getSearchParamsObject } from "../../";
import { SEARCH_PARAM_NAME_PAGE, SEARCH_PARAM_NAME_SEARCH } from "../constants";

const DEFAULT_MIN_SEARCH_LENGTH = 3;

export const useTableSearch = (
  minSearchLength: number = DEFAULT_MIN_SEARCH_LENGTH,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { search } = getSearchParamsObject(searchParams);

  const handleSearchSubmit = (value: string | undefined) => {
    if (value === undefined || value.length < minSearchLength) return;

    searchParams.delete(SEARCH_PARAM_NAME_PAGE);
    setSearchParams({
      ...getSearchParamsObject(searchParams),
      [SEARCH_PARAM_NAME_SEARCH]: value,
    });
  };

  const handleSearchClear = () => {
    searchParams.delete(SEARCH_PARAM_NAME_PAGE);
    searchParams.delete(SEARCH_PARAM_NAME_SEARCH);
    setSearchParams(searchParams);
  };

  return {
    handleSearchSubmit,
    handleSearchClear,
    searchValue: search as string | undefined,
  };
};
