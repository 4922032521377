import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetDebtItem = {
  archived: bnplMerchants.GetDebtResponseMany["archived"];
  compensatedAmount: bnplMerchants.GetDebtResponseMany["compensated_amount"];
  created: Date | undefined;
  currentDebtAmount: bnplMerchants.GetDebtResponseMany["current_debt_amount"];
  debtAmount: bnplMerchants.GetDebtResponseMany["debt_amount"];
  id: bnplMerchants.GetDebtResponseMany["id"];
  merchantId: bnplMerchants.GetDebtResponseMany["merchant_id"];
  orderId: bnplMerchants.GetDebtResponseMany["order_id"];
  orderNumber: bnplMerchants.GetDebtResponseMany["order_number"];
  refundCommissionAmount: bnplMerchants.GetDebtResponseMany["refund_commission_amount"];
  refundsAmount: bnplMerchants.GetDebtResponseMany["refund_commission_amount"];
  status: bnplMerchants.GetDebtResponseMany["status"];
  tradeOutletId: bnplMerchants.GetDebtResponseMany["trade_outlet_id"];
  updated: Date | undefined;
};

type GetDebtsResponseData = {
  items: GetDebtItem[] | undefined;
  summary: {
    totalCurrentDebt: bnplMerchants.GetDebtsSummary["total_current_debt"];
  };
  pagination: bnplMerchants.Pagination | undefined;
};

export const getDebts = async (
  options?: bnplMerchants.GetApiMerchantsServiceCrmDebtsParams,
): Promise<GetDebtsResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceDebts(options);

  return {
    items: response.data.data?.items?.map((i) => ({
      archived: i.archived,
      compensatedAmount: i.compensated_amount,
      created: i.created ? new Date(i.created) : undefined,
      currentDebtAmount: i.current_debt_amount,
      debtAmount: i.debt_amount,
      id: i.id,
      merchantId: i.merchant_id,
      orderId: i.order_id,
      orderNumber: i.order_number,
      refundCommissionAmount: i.refund_commission_amount,
      refundsAmount: i.refunds_amount,
      status: i.status,
      tradeOutletId: i.trade_outlet_id,
      updated: i.updated ? new Date(i.updated) : undefined,
    })),
    summary: {
      totalCurrentDebt: response.data.data?.summary?.total_current_debt || 0,
    },
    pagination: response.data.data?.pagination,
  };
};
