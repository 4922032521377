import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { ColumnDef, Row } from "@tanstack/react-table";
import { formatSettlementNumber, useSettlements } from "entities/settlements";
import { SettlementDetails } from "features/settlement-details";
import { UnpaidOrders } from "features/unpaid-orders";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSettlementsItem } from "shared/api";
import {
  analytics,
  format,
  formatDate,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableSummary,
  Box,
  Card,
  Label,
  LoadingOverlay,
  SearchInput,
  Show,
  SortingOptions,
  Stack,
  Typography,
} from "shared/ui";

import { getStatusMappingData } from "../lib";

export const SettlementList = () => {
  const { t } = useTranslation();

  const COLUMNS: ColumnDef<GetSettlementsItem, any>[] = [
    {
      accessorKey: "created",
      header: t("settlements.table.column.created"),
      cell: (cell) => {
        const value = cell.getValue();
        return value instanceof Date ? formatDate(value, "DD/MM/YYYY") : "";
      },
    },
    {
      accessorKey: "settlementNumber",
      header: t("settlements.table.column.settlement_number"),
      cell: (cell) => {
        const value = cell.getValue();
        return formatSettlementNumber(value);
      },
    },
    {
      accessorKey: "status",
      header: t("settlements.table.column.status"),
      cell: (cell) => {
        const { title, color } = getStatusMappingData(cell.getValue());
        return (
          <Label color={color} showIndicator={true}>
            {title}
          </Label>
        );
      },
    },
    {
      accessorKey: "salesAmount",
      header: t("settlements.table.column.sales_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: t("settlements.table.column.orders_count"),
      header: "Number of Orders",
    },
  ];

  const SORTING_OPTIONS: SortingOptions<GetSettlementsItem> = {
    created: [
      {
        name: t("common.table.sorting.date.desc"),
        id: "-created",
      },
      {
        name: t("common.table.sorting.date.asc"),
        id: "created",
      },
    ],
    status: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "-status",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "status",
      },
    ],
    salesAmount: [
      {
        name: t("common.table.sorting.number.desc"),
        id: "-sales_amount",
      },
      {
        name: t("common.table.sorting.number.asc"),
        id: "sales_amount",
      },
    ],
  };

  const pagination = useTablePagination();

  const showUnpaidOrders = useBoolean();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetSettlementsItem>(SORTING_OPTIONS);

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const settlements = useSettlements({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceSettlementsOrderBy,
    settlement_number: searchValue,
  });

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const summaryItems = [
    {
      key: "unpaid_orders",
      title: settlements.data?.summary?.unsettledOrdersCount || "",
      description: t("settlements.summary.unpaid_orders"),
      onClick: () => {
        analytics.logAction("ClickPayoutUnpaidOrders");

        showUnpaidOrders.on();
        setSelectedRowId(undefined);
      },
    },
  ];

  const handleRowSelect = (row: Row<GetSettlementsItem>) => {
    showUnpaidOrders.off();
    setSelectedRowId(row.id);
  };

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      settlements.data?.items?.length &&
      settlements.data?.items[0] &&
      !showUnpaidOrders.value
    ) {
      setSelectedRowId(settlements.data?.items[0].id);
    }
  }, [selectedRowId, settlements.data?.items, showUnpaidOrders.value]);

  const selectedSettlement = useMemo(() => {
    return settlements.data?.items?.find((item) => item.id === selectedRowId);
  }, [selectedRowId, settlements.data?.items]);

  const getRowId = (row: GetSettlementsItem) => row.id!;

  const isSearchVisible =
    (settlements.data?.items || []).length > 0 || Boolean(searchValue);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box sx={{ width: 744 }}>
          <BaseTableSummary items={summaryItems} />

          <Card sx={{ height: 557, mt: 1.5 }}>
            <Stack
              borderRadius={1}
              height="100%"
              sx={{ backgroundColor: "common.white" }}
            >
              <Stack
                p={2.5}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="headlineH1">
                  {t("settlements.title")}
                </Typography>

                <Show when={isSearchVisible}>
                  <SearchInput
                    color="gray"
                    onClear={handleSearchClear}
                    onSubmit={handleSearchSubmit}
                    defaultValue={searchValue}
                    inputWidth={300}
                    placeholder={t("settlements.search_input.placeholder")}
                  />
                </Show>
              </Stack>

              <BaseTable
                columns={COLUMNS}
                data={settlements.data?.items || []}
                sortingState={sortingState}
                onSortingChange={handleSortingChange}
                sortingOptions={SORTING_OPTIONS}
                isDataLoading={settlements.isLoading}
                selectedRowId={selectedRowId}
                onRowSelect={handleRowSelect}
                getRowId={getRowId}
                isFiltersEnabled={Boolean(searchValue)}
                noDataMessage={t("settlements.table.no_data_message")}
              />
              <BaseTablePagination
                state={{
                  ...pagination,
                  totalCount: settlements.data?.pagination?.total,
                }}
              />
            </Stack>
          </Card>
        </Box>

        <Card sx={{ height: 627, flex: 1 }}>
          {showUnpaidOrders.value ? (
            <UnpaidOrders />
          ) : (
            <SettlementDetails settlement={selectedSettlement} />
          )}
        </Card>
      </Stack>

      <LoadingOverlay open={settlements.isLoading} />
    </>
  );
};
