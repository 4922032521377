import { DebtList } from "widgets/debt-list";
import { LayoutMain } from "widgets/layout-main";

export const PageDebts = () => {
  return (
    <LayoutMain>
      <DebtList />
    </LayoutMain>
  );
};
