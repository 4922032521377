import { ROUTES } from "shared/routing";

type Params = {
  search?: string;
};

export const getSettlementsPath = (params: Params): string =>
  Object.entries(params).reduce(
    (res, [key, value]) => `${res}?${key}=${value}`,
    String(ROUTES.settlements),
  );
