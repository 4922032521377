import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "shared/ui";

type NoResultsProps = {
  onFiltersReset?: () => void;
};

export const NoResults = ({ onFiltersReset }: NoResultsProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack height="100%" alignItems="center" justifyContent="center" px={2}>
        <Typography variant="headlineH4" color="neutral.20">
          {t("common.table.no_results.title")}
        </Typography>
        <Typography mt={1} variant="regularTextRegular" color="neutral.70">
          {t("common.table.no_results.description")}
        </Typography>
        <Button
          onClick={onFiltersReset ?? undefined}
          sx={{ mt: 2.5 }}
          variant="secondary"
        >
          {t("common.table.clear_filters_button_text")}
        </Button>
      </Stack>
    </Box>
  );
};
