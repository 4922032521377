import { Box, Typography } from "shared/ui";

type NoDataProps = {
  message?: string;
};

export const NoData = ({ message }: NoDataProps) => {
  if (!message) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography variant="headlineH4" color="neutral.70">
        {message}
      </Typography>
    </Box>
  );
};
