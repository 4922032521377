import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { getSearchParamsObject } from "shared/lib";
import { FilterItem, FilterType } from "shared/ui";

import {
  SEARCH_PARAM_NAME_PAGE,
  SEARCH_PARAM_NAME_POSTFIX_GE,
  SEARCH_PARAM_NAME_POSTFIX_IN,
  SEARCH_PARAM_NAME_POSTFIX_LE,
} from "../constants";

const getFilterState = <T>(
  searchParamsObject: Record<string, string | string[]>,
  filtersConfig: FilterItem[],
) =>
  filtersConfig.reduce(
    (acc, { type, field }) => {
      if (type === FilterType.Range) {
        const dateFrom =
          searchParamsObject[`${field}${SEARCH_PARAM_NAME_POSTFIX_GE}`];
        const dateTo =
          searchParamsObject[`${field}${SEARCH_PARAM_NAME_POSTFIX_LE}`];

        return {
          ...acc,
          [field]:
            dateFrom &&
            dateTo &&
            typeof dateFrom === "string" &&
            typeof dateTo === "string"
              ? [new Date(dateFrom), new Date(dateTo)]
              : undefined,
        };
      } else if (type === FilterType.Multiple) {
        const value = searchParamsObject[`${field}In`];

        return {
          ...acc,
          [field]: typeof value === "string" ? value.split(",") : undefined,
        };
      }

      return acc;
    },
    {} as { [key in keyof T]: any[] },
  );

export const useTableFilters = <T>(config: FilterItem[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterChange = (
    field: string,
    value: string[] | Date[] | undefined,
    type: FilterItem["type"],
  ) => {
    searchParams.delete(SEARCH_PARAM_NAME_PAGE);

    if (type === FilterType.Range) {
      const paramNameGe = `${field}Ge`;
      const paramNameLe = `${field}Le`;

      if (value?.length === 2) {
        setSearchParams({
          ...getSearchParamsObject(searchParams),
          ...{
            [paramNameGe]:
              typeof value[0] === "object" ? value[0].toISOString() : "",
            [paramNameLe]:
              typeof value[1] === "object" ? value[1].toISOString() : "",
          },
        });
      } else {
        searchParams.delete(paramNameGe);
        searchParams.delete(paramNameLe);
        setSearchParams(searchParams);
      }
    }

    if (type === FilterType.Multiple) {
      const multipleParamName = `${field}${SEARCH_PARAM_NAME_POSTFIX_IN}`;

      if (value?.hasOwnProperty("length") && value?.length > 0) {
        setSearchParams({
          ...getSearchParamsObject(searchParams),
          ...{ [multipleParamName]: value.join(",") },
        });
      } else {
        searchParams.delete(multipleParamName);
        setSearchParams(searchParams);
      }
    }
  };

  const handleFiltersReset = () => {
    config.forEach((item) => {
      if (item.type === FilterType.Range) {
        searchParams.delete(`${item.field}${SEARCH_PARAM_NAME_POSTFIX_LE}`);
        searchParams.delete(`${item.field}${SEARCH_PARAM_NAME_POSTFIX_GE}`);
      } else {
        searchParams.delete(`${item.field}${SEARCH_PARAM_NAME_POSTFIX_IN}`);
      }
    });
    setSearchParams(searchParams);
  };

  const filterState = getFilterState<T>(
    getSearchParamsObject(searchParams),
    config,
  );

  const isFiltersEmpty = useMemo(
    () =>
      Object.values(filterState).every(
        (filterValue) => filterValue === undefined,
      ),
    [filterState],
  );

  return {
    handleFilterChange,
    handleFiltersReset,
    filterState,
    isFiltersEmpty,
  };
};
