import { useTranslation } from "react-i18next";
import { MenuItem, Select, SelectChangeEvent } from "shared/ui";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value === "string") {
      i18n.changeLanguage(e.target.value);
    }
  };

  return (
    <Select
      variant="filled"
      value={i18n.language}
      onChange={handleChange}
      size="small"
      sx={{ width: 88 }}
    >
      <MenuItem key="en" value="en">
        Eng
      </MenuItem>
      <MenuItem key="ms" value="ms">
        My
      </MenuItem>
    </Select>
  );
};
