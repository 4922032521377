import { IconArrowDown, IconArrowUp } from "@mychili/ui-web";
import { useTranslation } from "react-i18next";
import { format } from "shared/lib";
import { Box, Card, Stack, Typography } from "shared/ui";

type AverageOrderAmountProps = {
  amount: number | undefined;
  amountDiff: number | undefined;
};

export const AverageOrderAmount = ({
  amount = 0,
  amountDiff = 0,
}: AverageOrderAmountProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ p: 2.5, minHeight: 164 }}>
      <Typography variant="mediumTextSemiBold">
        {t("overview.average_order_amount.title")}
      </Typography>

      <Typography variant="headlineH2" mt={1.5}>
        {format(amount)}
      </Typography>

      <Box mt={1.5}>
        <Stack direction="row" spacing={0.5}>
          {amountDiff >= 0 ? (
            <IconArrowUp color="success" fontSize="small" />
          ) : (
            <IconArrowDown color="error" />
          )}
          <Typography
            color={amountDiff >= 0 ? "success.70" : "error.70"}
            variant="regularTextSemiBold"
          >
            {format(amountDiff)}
          </Typography>
        </Stack>
        <Typography color="neutral.50" variant="regularTextRegular">
          {t("overview.average_order_amount.previous_period.label")}
        </Typography>
      </Box>
    </Card>
  );
};
