import { IconSearch } from "@mychili/ui-web";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseButton } from "../close-button";
import { IconButton } from "../icon-button";
import { Input, InputProps } from "../input";
import { Stack } from "../stack";

export type SearchInputProps = Omit<InputProps, "onSubmit"> & {
  onClear: () => void;
  onSubmit: (value: string) => void;
  onButtonClick?: (value: string) => void;
  inputWidth?: number;
};

export const SearchInput = ({
  onClear,
  onSubmit,
  defaultValue,
  onButtonClick,
  inputWidth = 248,
  color = "white",
  ...inputProps
}: SearchInputProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue as string);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onButtonClick?.(value);
    onSubmit(value);
  };

  const handleClear = () => {
    setValue("");
    onClear();
  };

  const isEmpty = !value;

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <Input
        sx={{
          width: inputWidth,
        }}
        InputProps={{
          endAdornment: !isEmpty ? (
            <CloseButton onClick={handleClear} />
          ) : undefined,
          sx: {
            // @TODO Remove after adding small inputs to DS
            ".MuiInputBase-input": {
              cursor: "pointer",
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
        }}
        placeholder={t("common.search")}
        value={value}
        onChange={handleChange}
        color={color}
        {...inputProps}
      />

      <IconButton onClick={handleSubmit} color={color}>
        <IconSearch />
      </IconButton>
    </Stack>
  );
};
