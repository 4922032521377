import { ColumnDef } from "@tanstack/react-table";
import { getOrdersPath, useOrders } from "entities/orders";
import { useTranslation } from "react-i18next";
import { GetOrdersItem } from "shared/api";
import { useTablePaginationState } from "shared/lib";
import { format } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Box,
  IconToFill,
  InfoBlock,
  Loader,
  Stack,
  TableCellLinkButton,
  Typography,
} from "shared/ui";

export const UnpaidOrders = () => {
  const { t } = useTranslation();

  const pagination = useTablePaginationState();

  const unpaidOrders = useOrders({
    settled: false,
    limit: pagination.currentLimit,
    offset: pagination.offset,
  });

  const getRowId = (row: GetOrdersItem) => row.id!;

  const columns: ColumnDef<GetOrdersItem, any>[] = [
    {
      accessorKey: "orderNumber",
      header: t("settlements.unpaid_orders.column.order_number"),
      cell: (cell) => {
        const orderNumber = cell.getValue();
        return (
          <TableCellLinkButton
            text={orderNumber}
            path={getOrdersPath({ search: orderNumber })}
          />
        );
      },
    },
    {
      accessorKey: "amount",
      header: t("settlements.unpaid_orders.column.amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "commissionAmount",
      header: t("settlements.unpaid_orders.column.comission_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "settlementAmount",
      header: t("settlements.unpaid_orders.column.settlement_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "mdr",
      header: t("settlements.unpaid_orders.column.mdr"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? `${value}%` : "";
      },
    },
  ];

  if (unpaidOrders.isLoading) return <Loader />;

  return (
    <Stack sx={{ height: "100%" }} spacing={2.5}>
      <Stack
        p={2.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="neutral.95"
      >
        <Typography color="neutral.20" variant="headlineH4">
          {t("settlements.unpaid_orders.title")}
        </Typography>
      </Stack>

      <Box
        sx={{
          px: 2.5,
        }}
      >
        <Box sx={{ width: 148 }}>
          <InfoBlock
            title={t("settlements.unpaid_orders.total_amount.label")}
            description={
              unpaidOrders.data?.summary?.totalAmount
                ? format(unpaidOrders.data?.summary?.totalAmount)
                : "-"
            }
            Icon={IconToFill}
          />
        </Box>
      </Box>

      <Box sx={{ flex: 1 }}>
        <BaseTableWrapper sx={{ height: 460 }}>
          <BaseTable
            data={unpaidOrders.data?.items || []}
            isDataLoading={unpaidOrders.isLoading}
            columns={columns}
            getRowId={getRowId}
            noDataMessage={t("settlements.unpaid_orders.no_data_message")}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: unpaidOrders.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>
      </Box>
    </Stack>
  );
};
