import { GetDebtItem } from "shared/api";
import { toTitleCase } from "shared/lib";
import { LabelProps } from "shared/ui";

export const getStatusMappingData = (
  status: GetDebtItem["status"],
): { title: string; color: LabelProps["color"] } => {
  let color: LabelProps["color"] = "neutral";

  if (status === "ACTIVE") {
    color = "success";
  } else if (status === "CANCELLED") {
    color = "error";
  }

  return {
    title: toTitleCase(status),
    color,
  };
};
