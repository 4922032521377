import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  IconClose,
  IconFilterList,
  OptionsDropdown,
  OptionsDropdownProps,
  Popover,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import {
  FilterItem,
  FilterType,
  SortingOption,
  SortingOptions,
} from "../types";
import { Filter } from "./filter";
import { NoData } from "./no-data";
import { NoResults } from "./no-results";

type BaseTableProps<T> = {
  columns: ColumnDef<T, any>[];
  data: T[];
  getRowId: TableOptions<T>["getRowId"];
  onRowSelect?: (row: Row<T>) => void;
  selectedRowId?: string | null;
  onSortingChange?: (value: string | string[]) => void;
  sortingOptions?: SortingOptions<T>;
  sortingState?: SortingOption | undefined;
  filters?: FilterItem[];
  filterState?: any;
  onFiltersReset?: () => void;
  onFilterChange?: (
    field: string,
    values: string[] | Date[] | undefined,
    type: FilterType,
  ) => void;
  isDataLoading?: boolean;
  deletedColumnId?: string;
  onFilterDropdownOpen?: (field: string) => void;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  /*
    @todo refactor: get rid of isFiltersEnabled prop
    (is used to check whether filters and search are set to display correct no-data message instead of no-results message)
  */
  isFiltersEnabled?: boolean;
  noDataMessage?: string;
};

const SELECTION_COLUMN_CONFIG: ColumnDef<any, any> = {
  id: "selection",
  size: 40,
  header: ({ table }) => (
    <Checkbox
      checked={table.getIsAllRowsSelected()}
      onChange={() => {
        table.toggleAllRowsSelected();
      }}
      size="small"
      // @todo remove after updating Checkbox in ui-web
      disableRipple
      sx={{
        "&.MuiCheckbox-root": {
          padding: 0,
        },
      }}
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onChange={() => {
        row.toggleSelected();
      }}
      size="small"
      disableRipple
      // @todo remove after updating Checkbox in ui-web
      sx={{
        "&.MuiCheckbox-root": {
          padding: 0,
        },
      }}
    />
  ),
};

export const BaseTable = <T,>({
  data,
  columns,
  sortingState,
  onSortingChange,
  sortingOptions,
  filters,
  onFilterChange,
  filterState,
  onFiltersReset,
  selectedRowId,
  onRowSelect,
  isDataLoading,
  getRowId,
  deletedColumnId,
  onFilterDropdownOpen,
  rowSelection,
  onRowSelectionChange,
  isFiltersEnabled = false,
  noDataMessage,
}: BaseTableProps<T>) => {
  const { t } = useTranslation();

  const columnVisibility = deletedColumnId
    ? {
        [deletedColumnId]: false,
      }
    : undefined;

  const table = useReactTable({
    data,
    columns: rowSelection ? [SELECTION_COLUMN_CONFIG, ...columns] : columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    getRowId,
    onRowSelectionChange,
    defaultColumn: {
      size: 200,
      maxSize: 200,
    },
    state: {
      columnVisibility,
      rowSelection,
    },
  });

  const [activeSortingColumnKey, setActiveSortingColumnKey] =
    useState<keyof T>();

  const handleRowSelect = (row: Row<T>) => {
    if (onRowSelect) {
      onRowSelect(row);
    }
  };

  const [sortingAnchorEl, setSortingAnchorEl] =
    useState<HTMLTableCellElement | null>(null);

  const handleSortingClick = (
    columnKey: keyof T,
    e: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    setActiveSortingColumnKey(columnKey);
    setSortingAnchorEl(e.currentTarget);
  };

  const isDataEmpty = !isDataLoading && data.length === 0 && !isFiltersEnabled;
  const isFilteredDataEmpty =
    !isDataLoading && data.length === 0 && isFiltersEnabled;

  const isFilterVisible = filters && onFilterChange && !isDataEmpty;
  const isSortingVisible = sortingOptions && onSortingChange && !isDataEmpty;

  return (
    <>
      {/* Filters */}
      <Show when={isFilterVisible}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          pb={0}
        >
          <Stack direction="row" gap={1}>
            {filters?.map((filterProps) => (
              <Filter
                {...filterProps}
                key={filterProps.field}
                onChange={onFilterChange!}
                value={filterState[filterProps.field]}
                onDropdownOpen={onFilterDropdownOpen}
              />
            ))}
          </Stack>

          <Show when={isDataEmpty}>
            <Button
              onClick={onFiltersReset}
              variant="link"
              endIcon={<IconClose />}
            >
              {t("common.table.clear_filters_button_text")}
            </Button>
          </Show>
        </Stack>
      </Show>

      <>
        <Box
          sx={{ overflowY: "auto", position: "relative", height: "100%" }}
          mt={2}
        >
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{
              width: "100%",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {/* Header */}
            <thead style={{ position: "sticky", top: 0 }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index, arr) => {
                    const isSortable =
                      sortingOptions?.[header.column.id as keyof T];

                    return (
                      <th
                        key={header.id}
                        style={{ width: header.getSize() }}
                        onClick={
                          isSortable
                            ? (e) =>
                                handleSortingClick(
                                  header.column.id as keyof T,
                                  e,
                                )
                            : undefined
                        }
                      >
                        <Box
                          padding={1}
                          pr={0}
                          sx={{
                            backgroundColor: "neutral.96",
                            borderBottom: "1px solid",
                            borderColor: "neutral.90",
                            borderTopLeftRadius: index === 0 ? 8 : 0,
                            borderTopRightRadius:
                              index === arr.length - 1 ? 8 : 0,
                            cursor: isSortable ? "pointer" : "auto",
                            "&:hover": {
                              backgroundColor: "neutral.95",
                            },
                            "&:active": {
                              backgroundColor: "neutral.90",
                              borderColor: "neutral.80",
                            },
                            height: 41,
                            alignContent: "center",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            pr={1}
                            sx={{
                              borderRight:
                                index === arr.length - 1 ? "none" : "1px solid",
                              borderColor: "neutral.90",
                            }}
                          >
                            <Typography
                              variant="regularTextSemiBold"
                              whiteSpace="nowrap"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </Typography>
                            {isSortable && <IconFilterList />}
                          </Stack>
                        </Box>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            {/* Body */}
            {isDataEmpty ? (
              <NoData message={noDataMessage} />
            ) : isFilteredDataEmpty ? (
              <NoResults onFiltersReset={onFiltersReset} />
            ) : (
              <tbody>
                {table.getRowModel().rows.map((row, index, arr) => {
                  const isRowSelected = onRowSelect
                    ? row.id === selectedRowId
                    : false;
                  const isLastRow = index === arr.length - 1;

                  return (
                    <tr key={row.id} onClick={() => handleRowSelect(row)}>
                      {row.getVisibleCells().map((cell) => {
                        const isDeleted = deletedColumnId
                          ? row.getValue(deletedColumnId)
                          : false;

                        return (
                          <td key={cell.id}>
                            <Box
                              sx={{
                                cursor: onRowSelect ? "pointer" : "auto",
                                padding: 1,
                                verticalAlign: "top",
                                borderBottom: isLastRow
                                  ? undefined
                                  : "1px solid",
                                backgroundColor: isRowSelected
                                  ? "info.95"
                                  : "common.white",
                                borderBottomColor: isRowSelected
                                  ? "info.90"
                                  : "neutral.95",
                                height: "64px",
                              }}
                            >
                              <Typography
                                variant="regularTextRegular"
                                sx={{
                                  textDecoration: isDeleted
                                    ? "line-through"
                                    : undefined,
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Typography>
                            </Box>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </Box>
      </>

      {/* Sorting */}
      {isSortingVisible && (
        <Popover
          anchorEl={sortingAnchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={Boolean(sortingAnchorEl)}
          onClose={() => setSortingAnchorEl(null)}
        >
          <OptionsDropdown
            options={
              (sortingOptions[
                activeSortingColumnKey
              ] as OptionsDropdownProps["options"]) || []
            }
            value={sortingState?.id as string}
            onChange={onSortingChange}
            title={t("common.table.sorting.label")}
            type="radio"
          />
        </Popover>
      )}
    </>
  );
};
