import { ActionButton, IconChevronRight, Stack } from "shared/ui";

import { SummaryItem } from "../types";

type BaseTableSummaryProps = {
  items: SummaryItem[];
  color?: "gray" | "white";
};

export const BaseTableSummary = ({
  items,
  color = "white",
}: BaseTableSummaryProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        ".MuiPaper-root": {
          flex: 1,
          // @todo to DS
          ".MuiTypography-regularTextRegular": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 600,
          },
          ".MuiTypography-smallTextRegular": {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: 400,
          },
        },
      }}
    >
      {items.map(({ title, description, onClick, key }) => (
        <ActionButton
          key={key}
          text={title}
          description={description}
          onClick={onClick ?? undefined}
          endIcon={onClick ? <IconChevronRight /> : undefined}
          color={color}
        />
      ))}
    </Stack>
  );
};
