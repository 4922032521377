import { useQuery } from "@tanstack/react-query";
import { getDebtCompensations, GetDebtCompensationsParams } from "shared/api";

import { QUERY_KEY_DEBT_COMPENSATIONS } from "../../lib";

export const useDebtCompensations = (
  debtId?: string,
  params?: GetDebtCompensationsParams,
) =>
  useQuery({
    queryFn: () => getDebtCompensations(debtId, params),
    enabled: Boolean(debtId),
    queryKey: [QUERY_KEY_DEBT_COMPENSATIONS, debtId, params],
  });
